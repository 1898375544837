import { useState } from "react";
import { createBrowserRouter, Outlet, redirect, RouterProvider, useLoaderData, Routes, Route, Navigate, Link } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrashCan, faSpinner, faPencil, faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { useSignInQuery, useSignOutMutation } from './redux/apiSlice.js';
import './scss/App.scss'

// pages
import AppLoadingPage from './pages/AppLoadingPage';
import SignInPage from './pages/SignInPage';
import HomePage from './pages/HomePage';
import MarketPage from './pages/MarketPage';
import AudiencePage from './pages/AudiencePage';
import ProblemSpacePage from './pages/ProblemSpacePage';
import ProblemPage from './pages/ProblemPage';
import PrototypePage from './pages/PrototypePage';
import NewMarketPage from './pages/NewMarketPage';
import NewAudiencePage from './pages/NewAudiencePage';
import NewProblemSpacePage from './pages/NewProblemSpacePage';
import NewProblemPage from './pages/NewProblemPage';
import NewPrototypePage from './pages/NewPrototypePage';
import EditMarketPage from './pages/EditMarketPage';
import EditAudiencePage from './pages/EditAudiencePage';
import EditProblemSpacePage from './pages/EditProblemSpacePage';
import EditProblemPage from './pages/EditProblemPage';
import EditPrototypePage from './pages/EditPrototypePage';

// components
import AppNavBar from './components/Nav';

export default function App() {

  library.add(faTrashCan, faSpinner, faPencil, faCircleUser);

  const [isAuthenticated, setIsAuthenticated] = useState("undefined");

  getAuth().onAuthStateChanged((user) => {
    if (user) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  });

  let routes = [{ path: "*", Component: AppLoadingPage }];

  if (isAuthenticated === true) {
    routes = [
      { path: "/",
      Component: Layout,
      children: [
        { index: true, Component: HomePage },
        { path: "/market/:marketId", Component: MarketPage },
        { path: "/market/:marketId/edit", Component: EditMarketPage },
        { path: "/market/:marketId/audience/:audienceId", Component: AudiencePage },
        { path: "/market/:marketId/audience/:audienceId/edit", Component: EditAudiencePage },
        { path: "/market/:marketId/problem-space/:problemSpaceId", Component: ProblemSpacePage },
        { path: "/market/:marketId/problem-space/:problemSpaceId/edit", Component: EditProblemSpacePage },
        { path: "/market/:marketId/problem-space/:problemSpaceId/:prototypeId", Component: PrototypePage },
        { path: "/market/:marketId/problem-space/:problemSpaceId/:prototypeId/edit", Component: EditPrototypePage },
        { path: "/market/:marketId/problem-space/:problemSpaceId/new-prototype", Component: NewPrototypePage },
        { path: "/market/:marketId/problem-space/:problemSpaceId/problem/:problemId", Component: ProblemPage },
        { path: "/market/:marketId/problem-space/:problemSpaceId/problem/:problemId/edit", Component: EditProblemPage },
        { path: "/market/:marketId/problem-space/:problemSpaceId/new-problem", Component: NewProblemPage },
        { path: "/market/:marketId/new-problem-space", Component: NewProblemSpacePage },
        { path: "/market/:marketId/new-problem", Component: NewProblemPage },
        { path: "/market/:marketId/new-audience", Component: NewAudiencePage },
        { path: "/new-market", Component: NewMarketPage },
        { path: "/new-problem-space", Component: NewProblemSpacePage },
        { path: "/new-problem", Component: NewProblemPage },
        { path: "/new-audience", Component: NewAudiencePage},
        { path: "/new-prototype", Component: NewPrototypePage}
      ]},
      { path: "*",
        loader: () => {
          throw redirect('/');
        }
      }
    ]
  } else if (isAuthenticated === false) {
    routes = [
      { path: "/",
      Component: Layout,
      children: [
        { index: true, Component: SignInPage }
      ]},
      { path: "*",
        loader: () => {
          throw redirect('/');
        }
      }
    ]
  }

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;

  function Layout() {
    let content;

    if (isAuthenticated === true) {
      content =
      <>
        <div id="App">
          <AppNavBar isAuthenticated={isAuthenticated} />
          <Outlet />
        </div>
      </>
    } else {
      content =
      <>
        <div id="App">
          <div className="container">
            <Outlet />
          </div>
        </div>
      </>
    }

    return (
      <>
        {content}
      </>
    );
  };
};