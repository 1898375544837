import { useGetMarketsQuery} from '../redux/apiSlice.js';
import { Link } from 'react-router-dom';
import { useState } from 'react'
import { GetProblemSpaceCountByMarketId } from '../utils/GetFirestoreDocsById';
import { ReactComponent as CreatorThumbnail } from '../images/bald-man-listening-to-gramophone.svg';
import Button from 'react-bootstrap/Button';

function HomePage() {

  const {
    data: markets,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetMarketsQuery();

  return (
    <>
      <div className="container mb-5">
        <h6 className="text-muted">Hey Ali, what are you here to work on?</h6>
        <h1>Your markets ({markets?.length})</h1>
        <div className="row mt-5">
          { markets?.map(market => (
            <div key={market.id} className="col-lg-3">
              <div className="card mb-3">
                <Link to={`/market/${market.id}`} state={market}><CreatorThumbnail className="card-img-top" alt="Creator financing" /></Link>
                <div className="card-body p-4">
                  <h5 className="card-title fw-bold">{market.name}</h5>
                  <p className="card-text">Some placeholder text</p>
                  <p>0 problem spaces</p>
                  <Link to={`/market/${market.id}`}><Button variant="outline-primary">Get into it</Button></Link>
                </div>
              </div>
            </div>
          )) }
        </div>
      </div>
    </>
  )
}

export default HomePage;