import { Link, useParams } from 'react-router-dom';
import PrototypeForm from '../components/PrototypeForm';
import { useGetPrototypesQuery } from '../redux/apiSlice.js';
import Breadcrumbs from '../components/Breadcrumbs';

const EditPrototypePage = () => {

  const { prototypeId } = useParams();

  const { prototype } = useGetPrototypesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      prototype: data?.find((prototype) => prototype.id === prototypeId),
    })
  });

  let content;
  if (prototype) {
    content = <PrototypeForm prototype={prototype}/>
  } else {
    content = "Loading"
  }

  return (
    <>
      <Breadcrumbs />
      <div className="container">
        <p className="text-muted mt-5 mb-2">Edit</p>
        <h5>{prototype.name}</h5>
        {content}
      </div>
    </>
  );
}

export default EditPrototypePage;