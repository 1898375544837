import { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetMarketsQuery, useGetProblemSpacesQuery, useGetProblemsQuery, useDeleteProblemMutation } from '../redux/apiSlice.js';
import Breadcrumbs from '../components/Breadcrumbs';
import Button from 'react-bootstrap/Button';

const ProblemPage = () => {

  const navigate = useNavigate();
  const { marketId, problemSpaceId, problemId } = useParams();

  const { problem } = useGetProblemsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      problem: data?.find((p) => p.id === problemId),
    }),
  });

  const { 
    problemSpace,
    isSuccess: isGetProblemSpacesSuccess,
    isError: isGetProblemSpacesError,
    error: isGetProblemSpacesErrorMessage
  } = useGetProblemSpacesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      problemSpace: data?.find((space) => space.id === problemSpaceId),
    })
  });

  const { 
    market,
    isSuccess: isGetMarketsSuccess,
    isError: isGetMarketsError,
    error: isGetMarketsErrorMessage
  } = useGetMarketsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      market: data?.find((m) => m.id === problemSpace?.market),
    })
  });

  const [deleteProblem, result] = useDeleteProblemMutation();

  function deleteAndRedirect() {
    deleteProblem(problem.id);

    navigate("/");
    // if (result.isSuccess) {
    //   navigate("/");
    // }
  }

  return (
    <>
      <Breadcrumbs />
      <div className="container">
        <div className="row mt-5 mb-4">
          <h6 className="fw-light">Problem name</h6>
          <h5>{ problem && problem.title }</h5>
        </div>
        <div className="row mb-4">
          <h6 className="fw-light">Problem description</h6>
          <h5>{ problem && problem.description ? problem.description : "No description yet"}</h5>
        </div>
        <div className="row mb-4">
          <h6 className="fw-light">Problem statement</h6>
          <h5>{ problem && problem.statement ? problem.statement : "No statement yet" }</h5>
        </div>
        <div className="row mb-4">
          <h6 className="fw-light">Problem space</h6>
          <h5>{ problem && problem.space ? problem.space : "No space yet" }</h5>
        </div>
        <div className="row mb-4">
          <h6 className="fw-light">Status</h6>
          <h5>{ problem && problem.status ? problem.status : "No status yet" }</h5>
        </div>
        
        <Link to={"edit"} state={problem}><Button className="btn btn-outline-primary bg-transparent me-1">Edit</Button></Link>
        <Button className="btn-outline-danger bg-transparent" onClick={() => deleteAndRedirect()}><FontAwesomeIcon icon="trash-can" /></Button>
        
      </div>
    </>
  );
};

export default ProblemPage;