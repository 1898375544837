import { useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { useGetProblemSpacesQuery, useGetAudiencesQuery } from '../redux/apiSlice.js';
import { ReactComponent as CreatorThumbnail } from '../images/bald-man-listening-to-gramophone.svg';
import Button from 'react-bootstrap/Button';

const HomePageTab = ({ tab }) => {

  const { marketId } = useParams();

  const {
    market
  } = useGetProblemSpacesQuery(undefined, {
    selectFromResult: ({ data }) => ({  
      market: data?.filter(market => market.id == marketId),
    })
  });

  const {
    problemSpaces,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetProblemSpacesQuery(undefined, {
    selectFromResult: ({ data }) => ({  
      problemSpaces: data?.filter(space => space.market == marketId),
    })
  });

  const {
    audiences,
    isLoading: isAudienceLoading,
    isSuccess: isAudienceSuccess,
    isError: isAudienceError,
    error: isAudienceErrorMessage
  } = useGetAudiencesQuery(undefined, {
    selectFromResult: ({ data }) => ({  
      audiences: data?.filter(audience => audience.market == marketId),
    })
  });

  let content;

  if (tab == 1) {
    content =
      <div className="container mb-5">
        <div className="row">
          { problemSpaces?.map(ps => (
              <div key={ps.id} className="col-lg-3">
                <div className="card mb-3">
                  <Link to={`problem-space/${ps.id}`} state={ps}><CreatorThumbnail className="card-img-top" alt="Creator financing" /></Link>
                  <div className="card-body p-4">
                    <h5 className="card-title fw-bold">{ps.name}</h5>
                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                    <Link to={`problem-space/${ps.id}`}><Button variant="outline-primary">Explore</Button></Link>
                  </div>
                </div>
              </div>
          )) }
        </div>
      </div>;
    } else {
      content = 
        <div className="container mb-5">
          <div className="row">

            <Link to="new-audience" state={{market: market}}><Button variant="primary">Add new audience</Button></Link>

            { audiences?.map(audience => (
              <div key={audience.id} className="col-lg-6">
                <div className="card mb-3">
                  <div className="row g-0">
                    <div className="col-md-4">
                      <Link to={`audience/${audience.id}`}><CreatorThumbnail className="img-fluid rounded-start" alt="AI-assisted music" /></Link>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5 className="card-title">{audience.name}</h5>
                        <p className="card-text">{audience.definition}</p>
                        <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>;
    }

  return (
    <div>
      {content}
    </div>
  );
};

export default HomePageTab;