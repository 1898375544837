import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useGetProblemsQuery, useAddProblemMutation, useUpdateProblemMutation } from '../redux/apiSlice.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import ListItem from './ListItem';
import { ReactComponent as CreatorThumbnail } from '../images/bald-man-listening-to-gramophone.svg';
import { ReactComponent as MigrationThumbnail } from '../images/girl-going-with-the-luggage.svg';
import { ReactComponent as AiMusicThumbnail } from '../images/man-playing-guitar-on-sofa.svg';
import { ReactComponent as DesignThinkingThumbnail } from '../images/concept-about-business-director-got-success-in-business-startup.svg';
import { ReactComponent as GoogleDriveThumbnail } from '../images/business-lady-do-multi-tasking.svg';

const ProblemsList = (props) => {

  const problems = props.problems;

  let content;

  if (props.isLoading) {
    content = <FontAwesomeIcon icon="spinner" spin />
  } else {
    content =
      <>
        <div className="container">
          { problems?.map((problem,i)=>(
            <ListItem key={problem.id} problem={problem} />
            ))
          }
        </div>
      </>
  }

  return (
    <>
      {content}
    </>
  );
};

export default ProblemsList;