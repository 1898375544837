import { Link, useParams } from 'react-router-dom';
import ProblemForm from '../components/ProblemForm';
import { useGetProblemsQuery } from '../redux/apiSlice.js';
import Breadcrumbs from '../components/Breadcrumbs';

const EditProblemPage = () => {

  const { marketId, problemSpaceId, problemId } = useParams();

  const { problem } = useGetProblemsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      problem: data?.find((problem) => problem.id === problemId),
    })
  });

  let content;
  if (problem) {
    content = <ProblemForm problem={problem}/>
  } else {
    content = "Loading"
  }

  return (
    <>
      <Breadcrumbs />
      <div className="container">
        <p className="text-muted mt-5 mb-2">Edit</p>
        <h5>{problem.title}</h5>
        {content}
      </div>
    </>
  );
}

export default EditProblemPage;