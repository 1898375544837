import { Link } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import MarketForm from '../components/MarketForm';

const NewMarketPage = () => {

  return (
    <>
      <Breadcrumbs />
      <MarketForm />
    </>
  );
}

export default NewMarketPage;