import { Link } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import ProblemForm from '../components/ProblemForm';

const NewProblemPage = () => {

  return (
    <>
      <Breadcrumbs />
      <ProblemForm />
    </>
  );
}

export default NewProblemPage;