import { collection, query, getCountFromServer, where } from "firebase/firestore";
import db from '../firebase_config.js'
import { useGetMarketsQuery, useGetProblemSpacesQuery, useGetProblemsQuery } from '../redux/apiSlice';

function GetMarketById( id ) {
  const { market } = useGetMarketsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      market: data?.find((m) => m.id === id),
    }),
  })

  return market;
};

// async function GetProblemSpaceCountByMarketId( marketId ) {
//   const coll = collection(db, "problem-spaces");
//   const q = query(coll, where("market", "==", marketId));
//   const snapshot = await getCountFromServer(q);

//   return snapshot.data().count
// };

function GetProblemSpaceById(id) {
  const { problemsSpace } = useGetProblemSpacesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      problemsSpace: data?.find((ps) => ps.id === id),
    }),
  })

  return problemsSpace;
};

function GetProblemById(id) {
  const { problem } = useGetProblemsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      problem: data?.find((p) => p.id === id),
    }),
  })

  return problem;
};

export { GetMarketById, GetProblemSpaceById, GetProblemById };