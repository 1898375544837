import { Link, useParams } from 'react-router-dom';
import AudienceForm from '../components/AudienceForm';
import { useGetAudiencesQuery } from '../redux/apiSlice.js';
import Breadcrumbs from '../components/Breadcrumbs';

const EditAudiencePage = () => {

  const { marketId, audienceId } = useParams();

  const { audience } = useGetAudiencesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      audience: data?.find((problem) => audience.id === audienceId),
    })
  });

  let content;
  if (audience) {
    content = <AudienceForm audience={audience}/>
  } else {
    content = "Loading"
  }

  return (
    <>
      <Breadcrumbs />
      <div className="container">
        <p className="text-muted mt-5 mb-2">Edit</p>
        <h5>{audience.name}</h5>
        {content}
      </div>
    </>
  );
}

export default EditAudiencePage;