import { Link, useParams } from 'react-router-dom';
import MarketForm from '../components/MarketForm';
import { useGetMarketsQuery } from '../redux/apiSlice.js';
import Breadcrumbs from '../components/Breadcrumbs';

const EditMarketPage = () => {

  const { marketId } = useParams();

  const { market } = useGetMarketsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      market: data?.find((market) => market.id === marketId),
    })
  });

  let content;
  if (market) {
    content = <MarketForm market={market}/>
  } else {
    content = "Loading"
  }

  return (
    <>
      <Breadcrumbs />
      <div className="container">
        <p className="text-muted mt-5 mb-2">Edit</p>
        <h5>{market.name}</h5>
        {content}
      </div>
    </>
  );
}

export default EditMarketPage;