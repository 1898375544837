import { Link, useParams, useLocation } from "react-router-dom";
import { useGetMarketsQuery, useGetProblemSpacesQuery, useGetProblemsQuery } from '../redux/apiSlice';
import { GetMarketById, GetProblemSpaceById, GetProblemById } from '../utils/GetFirestoreDocsById';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function Breadcrumbs() {

  const { marketId, problemSpaceId, problemId } = useParams();

  const path = useLocation().pathname;
  let sections = path.split('/');

  const breadcrumbs = [];

  if (sections.includes('market')) {
    let market = GetMarketById(marketId);
    breadcrumbs.push({ name: "Markets", route: "/" });
  };

  if (sections.includes('problem-space')) {
    let market = GetMarketById(marketId);
    breadcrumbs.push({ name: market?.name, route: `/market/${marketId}` });
    breadcrumbs.push({ name: 'Problem Spaces', route: null });
  };

  if (sections.includes('problem')) {
    let problemSpace = GetProblemSpaceById(problemSpaceId);
    breadcrumbs.push({ name: problemSpace?.name, route: `/market/${marketId}/problem-space/${problemSpaceId}` });
    breadcrumbs.push({ name: 'Problems', route: null });
  };

  if (sections.includes('new-problem')) {
    breadcrumbs.push({ name: "New problem", route: "/" });;
  };

  return (
    <div className="container">
      <Breadcrumb>
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        {breadcrumbs.map( (crumb, i) => {
          if (i == breadcrumbs.length - 1) {
            return <Breadcrumb.Item key={i} active><span>{crumb.name}</span></Breadcrumb.Item>
          } else {
            return <Breadcrumb.Item key={i} ><Link to={crumb.route}>{crumb.name}</Link></Breadcrumb.Item>
          }
        })}
      </Breadcrumb>
    </div>
  );
}

export default Breadcrumbs;