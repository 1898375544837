import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazySignInQuery } from '../redux/apiSlice.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function SignInPage() {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");

  function handleFormSubmit(e) {
    e.preventDefault();
    
    setIsLoading(true);
    setIsError(false);

    const email = e.target.email.value;
    const password = e.target.password.value;
    
    signIn({email: email, password: password}).then(response => {
      
      setIsLoading(false);

      if (response.error) {
        setIsError(true);
        setMessage(response.error);
      }

      if (response.isSuccess) {
        navigate('/');
      }
    });
  }

  let errorCard = 
    <div class="alert alert-danger" role="alert">
      {message}
    </div>;

  const [signIn] = useLazySignInQuery();

  return (
    <div className="container col-10 col-sm-7 col-md-6 col-lg-4 col-xl-3 pt-5 pb-5">
      <h4 className="fw-bold mt-5">Do the wrrrrk.</h4>
      <Form className="mt-5" onSubmit={handleFormSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control type="email" name="email" placeholder="Enter email" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Control type="password" name="password" placeholder="Password"/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Remember me" />
        </Form.Group>
        <Button variant="primary" type="submit">
          { isLoading ? 'Loading' : 'Sign in' }
        </Button>
        <div className="mt-3">{isError && errorCard}</div>
      </Form>
    </div>
  );
}

export default SignInPage;