import { useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetMarketsQuery, useDeleteMarketMutation } from '../redux/apiSlice';
import { GetProblemCountByMarketId } from '../utils/GetFirestoreDocsById';
import MarketPageTabs from '../components/MarketPageTabs';
import Breadcrumbs from '../components/Breadcrumbs';
import Button from 'react-bootstrap/Button';

function MarketPage() {

  const navigate = useNavigate();
  const { marketId } = useParams();

  const {
    market,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetMarketsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      market: data?.find((market) => market.id === marketId),
    })
  });

  const [tab, setTab] = useState(1);

  function handleTabClick(e, tab) {
    setTab(tab);
  }

  const activeNavClassList = "nav-link active";

  const [deleteMarket, result] = useDeleteMarketMutation();

  function deleteAndRedirect() {
    deleteMarket(market.id);

    navigate("/");
    // if (result.isSuccess) {
    //   navigate("/");
    // }
  }

  return (
    <>
      <Breadcrumbs market={market} />
      <div className="container">
        <div className="row mt-5 mb-5">
          <h1>{market && market.name}</h1>
          <div>
            <Link to={"edit"} state={market}><Button className="btn btn-outline-primary bg-transparent me-1">Edit</Button></Link>
            <Button className="btn-outline-danger bg-transparent" onClick={() => deleteAndRedirect()}><FontAwesomeIcon icon="trash-can" /></Button>
          </div>
        </div>
        <div className="row">
          <ul className="nav nav-pills justify-content-center mb-5">
            <li className="nav-item">
              <Link className={tab == 1 ? activeNavClassList : 'nav-link'} onClick={(e) => handleTabClick(e, 1)}>Explore problem spaces</Link>
            </li>
            <li className="nav-item">
              <Link className={tab == 3 ? activeNavClassList : 'nav-link'} onClick={(e) => handleTabClick(e, 3)}>Research audiences</Link>
            </li>
          </ul>
        </div>
      </div>

      <MarketPageTabs tab={tab} />
    </>
  );
}

export default MarketPage;