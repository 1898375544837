import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './apiSlice.js';
import marketSlice  from './marketSlice.js'

export default configureStore({
  reducer: {
    [marketSlice.name]: marketSlice.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware)
})