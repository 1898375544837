import { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useGetProblemSpacesQuery, useGetProblemsQuery, useGetMarketsQuery, useDeleteProblemSpaceMutation } from '../redux/apiSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProblemsList from '../components/ProblemsList';
import Breadcrumbs from '../components/Breadcrumbs';
import Button from 'react-bootstrap/Button';

function ProblemSpacePage() {
  
  const navigate = useNavigate();
  const { marketId, problemSpaceId } = useParams();

  const {
    problems,
    isLoading: isGetProblemsLoading,
    isSuccess: isGetProblemsSuccess,
    isError: isGetProblemsError,
    error: isGetProblemsErrorMessage
  } = useGetProblemsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      problems: data?.filter((problem) => problem.space === problemSpaceId),
    })
  });

  const { 
    problemSpace,
    isSuccess: isGetProblemSpacesSuccess,
    isError: isGetProblemSpacesError,
    error: isGetProblemSpacesErrorMessage
  } = useGetProblemSpacesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      problemSpace: data?.find((space) => space.id === problemSpaceId),
    })
  });

  const { 
    market,
    isSuccess: isGetMarketsSuccess,
    isError: isGetMarketsError,
    error: isGetMarketsErrorMessage
  } = useGetMarketsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      market: data?.find((m) => m.id === problemSpace?.market),
    })
  });

  const [deleteProblemSpace, result] = useDeleteProblemSpaceMutation();

  function deleteAndRedirect() {
    deleteProblemSpace(problemSpace.id);

    navigate("/");
    // if (result.isSuccess) {
    //   navigate("/");
    // }
  }
 
  return (
    <>
      <Breadcrumbs />
      <div className="container mt-4">
        <p className="text-muted mb-1">Problem Space</p>
        <h5 className="fw-bold mt-1">{problemSpace && problemSpace.name}</h5>
        <p>{problemSpace.description}</p>
        <div>
          <Link to={"edit"} state={market}><Button className="btn btn-outline-primary bg-transparent me-1">Edit</Button></Link>
          <Button className="btn-outline-danger bg-transparent" onClick={() => deleteAndRedirect()}><FontAwesomeIcon icon="trash-can" /></Button>
        </div>
        <div className="mt-4 mb-2 text-end">
          <Link to="new-problem" state={{problemSpace: problemSpace}}><Button variant="primary">Add new problem</Button></Link>
        </div>
        <div>
          <ProblemsList problems={problems} isLoading={isGetProblemsLoading}/>
        </div>
      </div>
    </>
  );
}

export default ProblemSpacePage;