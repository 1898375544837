import { Link } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import AudienceForm from '../components/AudienceForm';

const NewProblemPage = () => {

  return (
    <>
      <Breadcrumbs />
      <AudienceForm />
    </>
  );
}

export default NewProblemPage;