import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useSignOutMutation } from '../redux/apiSlice.js';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import '../scss/Nav.scss';

function AppNavBar({ isAuthenticated }) {

  const market = useSelector((state) => state.market.market);

  function handleSignOut() {
    signOut()
  };

  const [signOut, user] = useSignOutMutation();

  return (
    <>
      {[false].map((expand) => (
        <Navbar key={expand} bg="white" expand={expand} className="mb-5">
          <Container fluid>
            <Navbar.Toggle className="me-auto" aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Brand className="brand-logo me-auto"><Link to="/">bidniz</Link></Navbar.Brand>

            { isAuthenticated && 
              <div className="me-2">
                <Dropdown as={ButtonGroup} size="sm">
                  <Button variant="outline-primary">Add new</Button>
                  <Dropdown.Toggle split variant="outline-primary" id="dropdown-split-basic" />
                  <Dropdown.Menu>
                    <Dropdown.Item><Link to="/new-market">Market</Link></Dropdown.Item>
                    <Dropdown.Item><Link to="/new-problem-space">Problem Space</Link></Dropdown.Item>
                    <Dropdown.Item><Link to="/new-problem">Problem</Link></Dropdown.Item>
                    <Dropdown.Item><Link to="/new-audience">Audience</Link></Dropdown.Item>
                    <Dropdown.Item><Link to="/new-prototype">Prototype</Link></Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            }

            { isAuthenticated && <Button variant="outline-primary" className="btn-sm" onClick={() => handleSignOut()}>Log out</Button> }
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="#action1">Problems</Nav.Link>
                  <Nav.Link href="#action2">Prototypes</Nav.Link>
                  <Nav.Link href="#action2">Account</Nav.Link>
                  <NavDropdown
                    title="Projects"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action3">
                      Donuts DATs
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="#action2">Wrrrrk</Nav.Link>
                </Nav>

                <hr className="mb-4"/>

                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="primary">Search</Button>
                </Form>

                <Button variant="outline-primary" className="mt-3">Log out</Button>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default AppNavBar;

// import { Link } from 'react-router-dom';
// import Button from 'react-bootstrap/Button';
// import Container from 'react-bootstrap/Container';
// import Form from 'react-bootstrap/Form';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import Offcanvas from 'react-bootstrap/Offcanvas';
// import '../../scss/Nav.scss';

// function AppNavBar() {
//   return (
//     <>
//       <Nav class="navbar navbar-light bg-light">
//         <div class="container-fluid">
//           <button class="navbar-toggler" type="button" data-mdb-toggle="collapse"
//             data-mdb-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent"
//             aria-expanded="false" aria-label="Toggle navigation">
//             <i class="fas fa-bars"></i>
//           </button>
//         </div>
//       </Nav>
//       <div class="collapse" id="navbarToggleExternalContent">
//         <div class="bg-light shadow-3 p-4">
//           <button class="btn btn-link btn-block border-bottom m-0">Link 1</button>
//           <button class="btn btn-link btn-block border-bottom m-0">Link 2</button>
//           <button class="btn btn-link btn-block m-0">Link 3</button>
//         </div>
//       </div>
//     </>
//   )
// }

// export default AppNavBar;