import { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetPrototypesQuery, useGetProblemSpacesQuery, useGetAudiencesQuery, useDeletePrototypeMutation } from '../redux/apiSlice.js';
import Breadcrumbs from '../components/Breadcrumbs';
import Button from 'react-bootstrap/Button';

const PrototypePage = () => {

  const navigate = useNavigate();
  const { marketId, problemSpaceId, audienceId, prototypeId } = useParams();

  const { prototype } = useGetPrototypesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      prototype: data?.find((p) => p.id === prototypeId),
    }),
  });

  const { 
    problemSpace,
    isSuccess: isGetMarketsSuccess,
    isError: isGetMarketsError,
    error: isGetMarketsErrorMessage
  } = useGetProblemSpacesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      problemSpace: data?.find((ps) => ps.id === prototype?.space),
    })
  });

  const [deletePrototype, result] = useDeletePrototypeMutation();

  function deleteAndRedirect() {
    deletePrototype(prototype.id);

    navigate("/");
    // if (result.isSuccess) {
    //   navigate("/");
    // }
  }

  return (
    <>
      <Breadcrumbs />
      <div className="container">
        <div className="row mt-5 mb-4">
          <h6 className="fw-light">Prototype name</h6>
          <h5>{ prototype && prototype.title }</h5>
        </div>
        <div className="row mb-4">
          <h6 className="fw-light">Prototype description</h6>
          <h5>{ prototype && prototype.description ? prototype.description : "No description yet"}</h5>
        </div>
        <div className="row mb-4">
          <h6 className="fw-light">prototype space</h6>
          <h5>{ prototype && prototype.space ? prototype.space : "No space yet" }</h5>
        </div>
        <div className="row mb-4">
          <h6 className="fw-light">Status</h6>
          <h5>{ prototype && prototype.status ? prototype.status : "No status yet" }</h5>
        </div>
        
        <Link to={"edit"} state={prototype}><Button className="btn btn-outline-primary bg-transparent me-1">Edit</Button></Link>
        <Button className="btn-outline-danger bg-transparent" onClick={() => deleteAndRedirect()}><FontAwesomeIcon icon="trash-can" /></Button>
        
      </div>
    </>
  );
};

export default PrototypePage;