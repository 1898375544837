import { useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useAddProblemSpaceMutation, useUpdateProblemSpaceMutation, useGetMarketsQuery } from '../redux/apiSlice.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

const ProblemSpaceForm = ( props ) => {

	const { marketId } = useParams();

	let initialState = {};

	if ( marketId && !props.problemSpace ) {
		initialState = {
			market: marketId
		};
	} else if (props.problemSpace) {
		initialState = props.problemSpace;
	} else {
		initialState = {};
	};

	const navigate = useNavigate();
	const [problemSpace, setProblemSpace] = useState(initialState);

	const {
    data: markets,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetMarketsQuery();

	function handleChange(e) {
		e.preventDefault();

    setProblemSpace({
      ...problemSpace,
      [e.target.name]: e.target.value
    });
  };

  function handleFormSubmit(e) {
  	e.preventDefault();

  	const data = new FormData(e.target);
  	setProblemSpace(data);

  	if (props.problemSpace) {
	    updateProblemSpace(problemSpace);

	    //HANDLE ERRORS
	    navigate("/market/" + problemSpace.market + "/problem-space/" + problemSpace.id);
  	} else {
	    addProblemSpace(problemSpace);

	    // HANDLE ERRORS AND REDIRECT TO NEW ID
	    navigate("/");
  	}
  };

  const [addProblemSpace, addProblemSpaceResult] = useAddProblemSpaceMutation();
	const [updateProblemSpace, updateProblemSpaceResult] = useUpdateProblemSpaceMutation();

	return (
		<div className="container">
			<div className="col-lg-8">
				<div className="row">

			    <Form className="mt-5" onSubmit={handleFormSubmit}>
			      <Form.Group as={Row} className="mb-3" controlId="formHorizontalName">
			        <Form.Label column sm={2}>
			          Problem space name
			        </Form.Label>
			        <Col sm={10}>
			          <Form.Control type="text" name="name" placeholder="Give it a name" value={problemSpace && problemSpace.name} onChange={(e) => handleChange(e)} />
			        </Col>
			      </Form.Group>

			      <Form.Group as={Row} className="mb-3" controlId="formHorizontalDescription">
			        <Form.Label column sm={2}>
			          Description
			        </Form.Label>
			        <Col sm={10}>
			          <Form.Control as="textarea" name="description" rows={3} placeholder="Enter a description" value={problemSpace && problemSpace.description} onChange={(e) => handleChange(e)} />
			        </Col>
			      </Form.Group>

			      <fieldset>
			        <Form.Group as={Row} className="mb-3">
			          <Form.Label as="legend" column sm={2}>
			            Market
			          </Form.Label>
			          <Col sm={10}>
			          	{ markets?.map(m => (
			          		 <Form.Check key={m.id}
				              type="radio"
				              label={m.name}
				              name="market"
				              id={"formHorizontal" + m.id}
				              value={m.id}
				              onChange={(e) => handleChange(e)}
				              checked={problemSpace && problemSpace.market == m.id}
				            />
			          	)) }
			          </Col>
			        </Form.Group>
			      </fieldset>

			      <Form.Group as={Row} className="mb-3">
			        <Col sm={{ span: 10, offset: 2 }}>
		          	{props.problemSpace && <Button type="submit">Update problem space</Button>}
		          	{!props.problemSpace && <Button type="submit">Create problem space</Button>}
			        </Col>
			      </Form.Group>
			    </Form>
			  </div>

			  <div className="col-lg-4 d-none d-lg-block">
			  	<Card style={{ width: '18rem' }}>
			      <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
			      <Card.Body>
			        <Card.Title>Card Title</Card.Title>
			        <Card.Text>
			          Some quick example text to build on the card title and make up the
			          bulk of the card's content.
			        </Card.Text>
			      </Card.Body>
			      <ListGroup className="list-group-flush">
			        <ListGroup.Item>Cras justo odio</ListGroup.Item>
			        <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
			        <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
			      </ListGroup>
			      <Card.Body>
			        <Card.Link href="#">Card Link</Card.Link>
			        <Card.Link href="#">Another Link</Card.Link>
			      </Card.Body>
			    </Card>
			  </div>
			</div> 
		</div>
	);
}

export default ProblemSpaceForm;