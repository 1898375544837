import { Link, useParams } from 'react-router-dom';
import ProblemSpaceForm from '../components/ProblemSpaceForm';
import { useGetProblemSpacesQuery } from '../redux/apiSlice.js';
import Breadcrumbs from '../components/Breadcrumbs';

const EditProblemSpacePage = () => {

  const { problemSpaceId } = useParams();

  const { problemSpace } = useGetProblemSpacesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      problemSpace: data?.find((problemSpace) => problemSpace.id === problemSpaceId),
    })
  });

  let content;
  if (problemSpace) {
    content = <ProblemSpaceForm problemSpace={problemSpace}/>
  } else {
    content = "Loading"
  }

  return (
    <>
      <Breadcrumbs />
      <div className="container">
        <p className="text-muted mt-5 mb-2">Editing problem space</p>
        <h5>{problemSpace.name}</h5>
        {content}
      </div>
    </>
  );
}

export default EditProblemSpacePage;