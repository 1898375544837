import { Link } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import PrototypeForm from '../components/PrototypeForm';

const NewPrototypeForm = () => {

  return (
    <>
      <Breadcrumbs />
      <PrototypeForm />
    </>
  );
}

export default NewPrototypeForm;