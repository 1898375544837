import { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetMarketsQuery, useGetAudiencesQuery, useDeleteAudienceMutation } from '../redux/apiSlice.js';
import Breadcrumbs from '../components/Breadcrumbs';
import Button from 'react-bootstrap/Button';

const AudiencePage = () => {

  const navigate = useNavigate();
  const { marketId, problemSpaceId, audienceId } = useParams();

  const { audience } = useGetAudiencesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      audience: data?.find((a) => a.id === audienceId),
    }),
  });

  const { 
    market,
    isSuccess: isGetMarketsSuccess,
    isError: isGetMarketsError,
    error: isGetMarketsErrorMessage
  } = useGetMarketsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      market: data?.find((m) => m.id === audience?.market),
    })
  });

  const [deleteAudience, result] = useDeleteAudienceMutation();

  function deleteAndRedirect() {
    deleteAudience(audience.id);

    navigate("/");
    // if (result.isSuccess) {
    //   navigate("/");
    // }
  }

  return (
    <>
      <Breadcrumbs />
      <div className="container">
        <div className="row mt-5 mb-4">
          <h6 className="fw-light">Audience name</h6>
          <h5>{ audience && audience.title }</h5>
        </div>
        <div className="row mb-4">
          <h6 className="fw-light">audience description</h6>
          <h5>{ audience && audience.description ? audience.description : "No description yet"}</h5>
        </div>
        <div className="row mb-4">
          <h6 className="fw-light">audience statement</h6>
          <h5>{ audience && audience.statement ? audience.statement : "No statement yet" }</h5>
        </div>
        <div className="row mb-4">
          <h6 className="fw-light">audience space</h6>
          <h5>{ audience && audience.space ? audience.space : "No space yet" }</h5>
        </div>
        <div className="row mb-4">
          <h6 className="fw-light">Status</h6>
          <h5>{ audience && audience.status ? audience.status : "No status yet" }</h5>
        </div>
        
        <Link to={"edit"} state={audience}><Button className="btn btn-outline-primary bg-transparent me-1">Edit</Button></Link>
        <Button className="btn-outline-danger bg-transparent" onClick={() => deleteAndRedirect()}><FontAwesomeIcon icon="trash-can" /></Button>
        
      </div>
    </>
  );
};

export default AudiencePage;