import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../scss/ListItem.scss'

const ListItem = ({ problem }) => {

	return (
	<div className="row list-item mb-2">
		<Link className="col mb-0 p-3 bg-white border border-grey rounded" to={`problem/${problem.id}`} problem={problem} state={problem}>{problem.title}</Link>
	</div>
	)
}

export default ListItem;