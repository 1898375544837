import { Link } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import ProblemSpaceForm from '../components/ProblemSpaceForm';

const NewProblemSpacePage = () => {

  return (
    <>
      <Breadcrumbs />
      <ProblemSpaceForm />
    </>
  );
}

export default NewProblemSpacePage;