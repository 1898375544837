import { createSlice } from '@reduxjs/toolkit'

const marketSlice = createSlice({
  name: 'market',
  initialState: { market: {}},
  reducers: {
    setMarket(state, action) {
    	state.market = action.payload
    }
  },
  // extraReducers: (builder) => {
  // 	// recommended API in the docs (vs. 'reducers')
  //   builder.addCase(setProblemSpace, (state, action) => {
  //     return state + action.payload
  //   })
  // },
})	

export const { setMarket } = marketSlice.actions
export default marketSlice